<template>
  <div class="latest-posts">
    <h4>Latest Blog posts</h4>
    <ul>
      <li v-for="articleId in blogTitles.slice().reverse()" :key="articleId">
        <blog-posts-link v-bind="articleId"></blog-posts-link>
      </li>
    </ul>
  </div>
</template>
<script>
import Vue2Filters from "vue2-filters";
import BlogService from "@/services/BlogService";
import BlogPostsLink from "@/components/BlogPostsLink";
export default {
  name: "blog-posts",
  components: {
    BlogPostsLink
  },
  data() {
    return {
      airtableResponse: []
    };
  },
  mounted: function() {
    let self = this;

    async function getBlogs() {
      try {
        const response = await BlogService.getBlogs();
        self.airtableResponse = response.data.records;
      } catch (err) {
        console.log(err);
      }
    }

    getBlogs();
  },
  computed: {
    blogTitles() {
      let self = this;
      let articleList = [];
      for (let i = 0; i < self.airtableResponse.length; i++) {
        if (self.airtableResponse[i].fields.Published) {
          let articleTitle = {
            slug: self.airtableResponse[i].fields.Slug,
            titleBlog: self.airtableResponse[i].fields.BlogTitle,
            cat: self.airtableResponse[i].fields.Category,
            date: self.airtableResponse[i].fields.Date
          };
          articleList.push(articleTitle);
        }
      }
      return articleList;
    }
  },
  mixins: [Vue2Filters.mixin]
};
</script>
