<template>
  <span>
    <pre class="cat">[{{ cat }}]</pre>
    <router-link to="/blog/" v-scroll-to="'#' + slug">
      {{ titleBlog }}
    </router-link>
  </span>
</template>

<script>
export default {
  name: "BlogPostsLink",
  props: {
    titleBlog: String,
    slug: String,
    cat: String
  }
};
</script>
