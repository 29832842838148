<template>
  <section id="reviews">
    <div class="ic-quote">
      <img src="../assets/ic-quotes.svg" alt="Quotes" />
      <img src="../assets/ic-quotes.svg" alt="Quotes" />
    </div>
    <div class="container">
      <h2>What other people say</h2>
      <div class="reviews-container">
        <div class="review">
          <div class="flag">
            <img src="../assets/canada.svg" alt="Canadian flag" />
          </div>
          <blockquote>
            <p>
              "I've worked with Andries on multiple projects now and he's been
              fantastic. He understands what we need very easily, is very
              communicative and his production is high quality. He has become
              our first choice for web development with our clients and we're
              happy to partner with him."
            </p>
            <cite>
              <p>- Jay Kiff, Snap Remedy</p>
            </cite>
          </blockquote>
        </div>
        <div class="review">
          <div class="flag">
            <img src="../assets/suisse.svg" alt="Swiss flag" />
          </div>
          <blockquote>
            <p>
              "Andries Hannaart has helped us on a wide range of print and web
              design projects and I have been nothing but delighted with the
              process and results. He has a rare combination of creative vision,
              deep technical expertise, high efficiency, and infectious
              enthusiasm, which makes working with him a real pleasure. I
              recommend him unreservedly!"
            </p>
            <cite>
              <p>- Christophe Dessimoz, Professor at University of Lausanne</p>
            </cite>
          </blockquote>
        </div>
        <div class="review">
          <div class="flag">
            <img src="../assets/holland.svg" alt="Dutch flag" />
          </div>
          <blockquote>
            <p>
              "Andries verweeft kennis, kunde en ervaring met een enorme
              creativiteit. Hij luistert naar wat ik voor ogen heb en weet dit
              heel gedetailleerd te vertalen naar beeld. Hij is doorgegaan
              totdat ik helemaal tevreden was!"
            </p>
            <cite>
              <p>- Mart van Hamersveld, mARTaudio.nl</p>
            </cite>
          </blockquote>
        </div>
      </div>
      <router-link v-scroll-to="'#review-modal'" to="/reviews">
        More reviews
        <img src="../assets/ic-arrow-light.svg" alt="Arrow icon" />
      </router-link>
    </div>
  </section>
</template>
<script>
export default {
  name: "review"
};
</script>
