<template>
  <div class="contact-form-container">
    <h4>Write me a quick note</h4>
    <form id="msgMe" class="contact-form" @submit.prevent="submit">
      <label for="name">Name</label>
      <div class="error-summary" v-if="$v.form.name.$error">
        Needs at least 2 characters and is required.
      </div>
      <input
        type="text"
        id="name"
        name="user_name"
        placeholder="Please tell me your name"
        class="input"
        v-model="form.name"
        :class="{ hasError: $v.form.name.$error }"
      />

      <label for="email">Email</label>
      <div class="error-summary" v-if="$v.form.email.$error">
        Needs a valid format and is required.
      </div>
      <input
        type="email"
        id="email"
        name="user_email"
        placeholder="E.g. yourname@domain.com"
        class="input"
        v-model="form.email"
        :class="{ hasError: $v.form.email.$error }"
      />

      <label for="your_message">Message</label>
      <div class="error-summary" v-if="$v.form.message.$error">
        Needs a minimum of 10 characters and is required.
      </div>
      <textarea
        id="your_message"
        name="message"
        placeholder="Write your message here..."
        v-model="form.message"
        :class="{ hasError: $v.form.message.$error }"
      ></textarea>

      <div class="error-summary" v-if="$v.form.spam.$error">
        Please fill in the correct number.
      </div>
      <sub>22 + eight =</sub>
      <div class="anti-spam-submit">
        <input
          type="text"
          id="ant-spm"
          placeholder="Answer..."
          :class="{ hasError: $v.form.spam.$error }"
          v-model="form.spam"
          aria-label="Anti Spam Question"
        />
        <button type="submit" class="button">
          Send message
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
import { required, email, minLength, between } from "vuelidate/lib/validators";

export default {
  name: "contact-form",

  data() {
    return {
      form: {
        name: "",
        email: "",
        message: "",
        spam: ""
      }
    };
  },

  validations: {
    form: {
      name: { required, min: minLength(2) },
      email: { required, email },
      message: { required, min: minLength(10) },
      spam: { required, between: between(30, 30) }
    }
  },

  methods: {
    submit(e) {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      // to form submit after this
      emailjs.sendForm(
        "andries_smtp_server",
        "template_qNrS2lbk",
        e.target,
        "user_vgCGy0iyCj47PbDgW1GMJ"
      );
      alert("Thank you for your message!");
      this.form.name = "";
      this.form.email = "";
      this.form.message = "";
      this.form.spam = "";
      this.$v.$reset();
    }
  }
};
</script>
