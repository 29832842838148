<template>
  <header>
    <div class="container">
      <section id="opening-text">
        <div class="show-all">
          <h1>
            <span class="graphic">Graphic Design</span> &amp;
            <span>Web</span> Development
          </h1>
        </div>
        <div class="show-ios">
          <h1><span>Graphic</span></h1>
          <h1>
            <span class="design">Design</span> &amp;
            <span>Web</span> Development
          </h1>
        </div>
        <p>
          Whether you would like a new website, logo, brochure or a complete
          brand (re)design, I'd be happy to help with your project.
        </p>
        <p>Please have a look at some of my current and past projects.</p>
      </section>
    </div>
  </header>
</template>

<script>
export default {
  mounted() {
    let isIOS =
      (/iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1)) &&
      !window.MSStream;

    const showAll = document.querySelector(".show-all");
    const showMobileSafari = document.querySelector(".show-ios");

    if (isIOS) {
      showAll.style.display = "none";
      showMobileSafari.style.display = "block";
    }
  }
};
</script>
