<template>
  <section id="projects">
    <div class="container">
      <div v-if="isLoading">
        Loading projects...
      </div>
      <div v-else>
        <div class="row" v-if="projects.length > 0">
          <div
            class="half"
            v-for="(project, index) in orderBy(projects, 'date').slice(0, 6)"
            :key="index"
          >
            <project-card v-bind="project"></project-card>
          </div>
        </div>
        <div id="view-all-projects">
          <router-link v-scroll-to="'#projects'" to="/projects"
            >View more projects
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30.2 15.53">
              <g id="arrow_layer">
                <g id="arrow_layer_2">
                  <rect
                    class="arrow-cls-1"
                    y="6.76"
                    width="28.5"
                    height="2"
                    rx="1"
                  />
                  <path
                    class="arrow-cls-1"
                    d="M21.19,15.21h0a1,1,0,0,1,.06-1.41L27,8.5A1,1,0,0,0,27,7l-5.71-5.3a1,1,0,0,1,0-1.41h0a1,1,0,0,1,1.42,0L29.88,7a1,1,0,0,1,0,1.47l-7.27,6.76A1,1,0,0,1,21.19,15.21Z"
                  />
                </g>
              </g>
            </svg>
          </router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Vue2Filters from "vue2-filters";
import ProjectsService from "@/services/ProjectsService";
import ProjectCard from "@/components/ProjectCard";

export default {
  name: "projects",
  components: {
    ProjectCard
  },
  data() {
    return {
      airtableResponse: [],
      isLoading: true  // Initial loading state
    };
  },
  mounted: async function() {
    try {
      const response = await ProjectsService.getProjects();
      this.airtableResponse = response.data.records;
    } catch (err) {
      console.error(err);
    } finally {
      this.isLoading = false;  // Set loading state to false when projects are fetched
    }
  },
  computed: {
    projects() {
      return this.airtableResponse
        .filter(project => project.fields.Published)
        .map(project => ({
          title: project.fields.Title,
          date: project.fields["Date Published"],
          body: project.fields.Body,
          catdesign: project.fields.CatDesign,
          catdev: project.fields.CatDev,
          image: project.fields.Image[0].url,
          slug: project.fields.Slug
        }));
    }
  },
  mixins: [Vue2Filters.mixin]
};
</script>