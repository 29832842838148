<template>
  <div>
    <Heading
      headerTitle="Projects"
      headingSubTxt="Here you will find a selection of the projects I'm working on or have worked on in the past."
    />
    <section id="projects" class="section">
      <div class="container">
        <div v-if="isLoading">
          Loading projects...
        </div>
        <div v-else>
          <div class="row">
            <div
              class="half"
              v-for="project in orderBy(projects, 'date')"
              :key="project"
            >
              <project-card v-bind="project"></project-card>
            </div>
          </div>
        </div>
      </div>
    </section>
    <Contact />
  </div>
</template>

<script>
import Vue2Filters from "vue2-filters";
import ProjectsService from "@/services/ProjectsService";
import ProjectCard from "@/components/ProjectCard";
import Heading from "@/components/Heading";
import Contact from "@/components/Contact";

export default {
  name: "projects",
  components: {
    Heading,
    ProjectCard,
    Contact
  },
  data() {
    return {
      airtableResponse: [],
      isLoading: true  // Initial loading state
    };
  },
  mounted: async function() {
    try {
      const response = await ProjectsService.getProjects();
      console.log(response);
      this.airtableResponse = response.data.records;
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;  // Set loading state to false when projects are fetched
    }
  },
  computed: {
    projects() {
      return this.airtableResponse
        .filter(project => project.fields.Published)
        .map(project => ({
          title: project.fields.Title,
          date: project.fields["Date Published"],
          body: project.fields.Body,
          catdesign: project.fields.CatDesign,
          catdev: project.fields.CatDev,
          image: project.fields.Image[0].url,
          slug: project.fields.Slug
        }));
    }
  },
  mixins: [Vue2Filters.mixin]
};
</script>