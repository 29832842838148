import axios from "axios";

// Replace YOUR_BASE_ID and YOUR_TABLE_ID_OR_NAME with your actual Airtable base ID and table ID or name
const baseURL = "https://api.airtable.com/v0/apphyfTwMlcKtfZk4/Projects";

const Axios = axios.create({
  baseURL,
});

// Assuming you have set the personal access token in your .env.local file
const airtableToken = process.env.VUE_APP_AIRTABLE_TOKEN;

Axios.defaults.headers.common = { Authorization: `Bearer ${airtableToken}` };

export default {
  getProjects() {
    // You can add any additional parameters or filters as needed
    return Axios.get("/", {
      params: {
        filterByFormula: "{Title}",
      },
    });
  },
  getProject(slug) {
    // You can add any additional parameters or filters as needed
    return Axios.get("/", {
      params: {
        filterByFormula: `{Slug}='${slug}'`,
      },
    });
  },
};