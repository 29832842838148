<template>
  <div id="app">
    <button
      type="button"
      name="Toggle Dark/Light mode"
      class="toggle-dark"
      aria-label="Toggle dark or light mode"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 223 223">
        <g id="Layer_2" data-name="Layer 2">
          <g id="Layer_1-2" data-name="Layer 1">
            <rect
              class="toggle-cls-1"
              x="109.5"
              y="1.5"
              width="4"
              height="20"
              rx="2"
            />
            <path
              class="toggle-cls-1"
              d="M111.5,1.5a2,2,0,0,0-2,2v16a2,2,0,0,0,4,0V3.5a2,2,0,0,0-2-2Z"
            />
            <rect
              class="toggle-cls-1"
              x="109.5"
              y="201.5"
              width="4"
              height="20"
              rx="2"
            />
            <path
              class="toggle-cls-1"
              d="M111.5,201.5a2,2,0,0,0-2,2v16a2,2,0,0,0,4,0v-16a2,2,0,0,0-2-2Z"
            />
            <rect
              class="toggle-cls-1"
              x="209.5"
              y="101.5"
              width="4"
              height="20"
              rx="2"
              transform="translate(323 -100) rotate(90)"
            />
            <path
              class="toggle-cls-1"
              d="M219.5,109.5h-16a2,2,0,0,0,0,4h16a2,2,0,0,0,0-4Z"
            />
            <rect
              class="toggle-cls-1"
              x="9.5"
              y="101.5"
              width="4"
              height="20"
              rx="2"
              transform="translate(123 100) rotate(90)"
            />
            <path
              class="toggle-cls-1"
              d="M19.5,109.5H3.5a2,2,0,0,0,0,4h16a2,2,0,0,0,0-4Z"
            />
            <rect
              class="toggle-cls-1"
              x="180.21"
              y="30.79"
              width="4"
              height="20"
              rx="2"
              transform="translate(82.21 -116.9) rotate(45)"
            />
            <path
              class="toggle-cls-1"
              d="M187.87,33.13a2,2,0,0,0-1.42.59L175.14,45a2,2,0,0,0,0,2.83h0a2,2,0,0,0,2.83,0l11.31-11.31a2,2,0,0,0,0-2.83h0a2,2,0,0,0-1.41-.59Z"
            />
            <rect
              class="toggle-cls-1"
              x="38.79"
              y="172.21"
              width="4"
              height="20"
              rx="2"
              transform="translate(140.79 24.53) rotate(45)"
            />
            <path
              class="toggle-cls-1"
              d="M46.45,174.56a2,2,0,0,0-1.42.58L33.72,186.45a2,2,0,0,0,0,2.83h0a2,2,0,0,0,2.83,0L47.86,178a2,2,0,0,0,0-2.83h0a2,2,0,0,0-1.41-.58Z"
            />
            <rect
              class="toggle-cls-1"
              x="38.79"
              y="30.79"
              width="4"
              height="20"
              rx="2"
              transform="translate(-16.9 40.79) rotate(-45)"
            />
            <path
              class="toggle-cls-1"
              d="M35.13,33.13a2,2,0,0,0-1.41.59h0a2,2,0,0,0,0,2.83L45,47.86a2,2,0,0,0,2.83,0h0a2,2,0,0,0,0-2.83L36.55,33.72a2,2,0,0,0-1.42-.59Z"
            />
            <rect
              class="toggle-cls-1"
              x="180.21"
              y="172.21"
              width="4"
              height="20"
              rx="2"
              transform="translate(-75.47 182.21) rotate(-45)"
            />
            <path
              class="toggle-cls-1"
              d="M176.55,174.56a2,2,0,0,0-1.41.58,2,2,0,0,0,0,2.83l11.31,11.31a2,2,0,1,0,2.83-2.83L178,175.14a2,2,0,0,0-1.42-.58Z"
            />
            <path
              class="toggle-cls-3"
              d="M64.12,64.12a67.09,67.09,0,0,0,0,94.76l94.76-94.76a67,67,0,0,0-94.76,0Z"
            />
            <path
              class="toggle-cls-4"
              d="M162.41,60.59a72,72,0,1,0-90.53,111c2,1.32,4.05,2.52,6.14,3.62a70.8,70.8,0,0,0,13,5.27,72.36,72.36,0,0,0,30.84,2.23q3.42-.49,6.8-1.32a70.74,70.74,0,0,0,10-3.21q3.25-1.32,6.39-3c2.09-1.1,4.14-2.3,6.14-3.62a71.94,71.94,0,0,0,5.82-4.29,70.28,70.28,0,0,0,5.47-4.94A72,72,0,0,0,162.41,60.59ZM64.12,158.88a67,67,0,0,1,94.76-94.76Z"
            />
          </g>
        </g>
      </svg>
    </button>
    <nav>
      <div class="container-fluid">
        <div id="logo-andries-hannaart">
          <router-link v-scroll-to="'body'" to="/">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlns:xlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 125.47 126.61"
            >
              <defs>
                <linearGradient
                  id="greenfade"
                  y1="63.3"
                  x2="125.47"
                  y2="63.3"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0" class="green-1" />
                  <stop offset="1" class="green-2" />
                </linearGradient>
              </defs>
              <g id="logo_layer">
                <g id="logo_layer_2">
                  <path
                    class="logo-cls-1"
                    d="M99.71,113.69l-40.58,12a22.14,22.14,0,0,1-21.54-5.17L6.9,91.37A22.15,22.15,0,0,1,.61,70.13L10.51,29A22.12,22.12,0,0,1,25.76,12.92L66.35.91A22.17,22.17,0,0,1,87.89,6.09l30.68,29.14a22.16,22.16,0,0,1,6.29,21.25L115,97.62A22.18,22.18,0,0,1,99.71,113.69Z"
                  />
                  <path
                    class="logo-cls-2"
                    d="M82.06,84.94H67.28V68.42H82.06V41.13q0-9.85-8.84-9.86H43.41V41.66h9.37V57.94H43.41V68.42H59.07V84.94H43.41V95.33H82.06Zm-21.4-27V41.66h9q3.57,0,3.57,3.19V57.94Z"
                  />
                </g>
              </g>
            </svg>
          </router-link>
        </div>
        <a
          href="#"
          id="toggle-mobile-nav"
          aria-label="Toggle Mobile Navigation"
        >
          <span></span>
          <span></span>
        </a>
        <ul id="navList">
          <li>
            <router-link v-scroll-to="'#projects'" to="/#projects"
              >Projects</router-link
            >
          </li>
          <li>
            <router-link v-scroll-to="'#services'" to="/#services"
              >Services</router-link
            >
          </li>
          <li>
            <router-link v-scroll-to="'#about'" to="/#about">About</router-link>
          </li>
          <li>
            <router-link v-scroll-to="'#contact'" to="/#contact"
              >Contact</router-link
            >
          </li>
          <li>
            <router-link v-scroll-to="'body'" to="/blog">Blog</router-link>
          </li>
        </ul>
      </div>
    </nav>
    <router-view />
  </div>
</template>

<script>
export default {
  mounted() {
    //toggle dark mode
    const myBody = document.getElementsByTagName("body")[0];
    const elToggle = document.querySelector(".toggle-dark");

    elToggle.onclick = function() {
      myBody.classList.toggle("dark-mode");
    };
    //toggle mobile nav
    const blockBodyScroll = document.getElementsByTagName("body")[0];
    const toggleNav = document.getElementById("toggle-mobile-nav");
    const ulMob = document.getElementById("navList");
    toggleNav.onclick = function() {
      this.classList.toggle("open-close");
      ulMob.classList.toggle("toggle-nav");
      blockBodyScroll.classList.toggle("block-scroll");
    };

    ulMob.onclick = function() {
      ulMob.classList.remove("toggle-nav");
      toggleNav.classList.remove("open-close");
      blockBodyScroll.classList.remove("block-scroll");
    };

    const closeOnLogo = document.getElementById("logo-andries-hannaart");
    closeOnLogo.onclick = function() {
      ulMob.classList.remove("toggle-nav");
      toggleNav.classList.remove("open-close");
      blockBodyScroll.classList.remove("block-scroll");
    };
    let d = new Date();
    let h = d.getHours();
    if (h > 19 || h < 7) {
      document.body.className = "dark-mode";
    }
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:wght@400;700;900&family=Space+Mono:wght@400;700&display=swap");

#app {
  font-family: "Red Hat Display", Avenir, Helvetica, Arial, sans-serif;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
