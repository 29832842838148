<template>
  <div>
    <Heading
      headerTitle="Design projects"
      headingSubTxt="Here you will find a selection of the projects I did the design for."
    />
    <section id="projects" class="section">
      <div class="container">
        <div class="row">
          <div
            class="half"
            v-for="project in orderBy(projects, 'date')"
            :key="project"
            v-show="project.catdesign"
          >
            <project-card v-bind="project"> </project-card>
          </div>
        </div>
      </div>
    </section>
    <Contact />
  </div>
</template>

<script>
import Vue2Filters from "vue2-filters";
import ProjectsService from "@/services/ProjectsService";
import ProjectCard from "@/components/ProjectCard";
import Heading from "@/components/Heading";
import Contact from "@/components/Contact";

export default {
  name: "projects",
  components: {
    Heading,
    ProjectCard,
    Contact
  },
  data() {
    return {
      airtableResponse: []
    };
  },
  mounted: function() {
    let self = this;

    async function getProjects() {
      try {
        const response = await ProjectsService.getProjects();
        console.log(response);
        self.airtableResponse = response.data.records;
      } catch (err) {
        console.log(err);
      }
    }

    getProjects();
  },
  computed: {
    projects() {
      let self = this;
      let projectList = [];
      for (let i = 0; i < self.airtableResponse.length; i++) {
        if (self.airtableResponse[i].fields.Published) {
          let project = {
            title: self.airtableResponse[i].fields.Title,
            myD: self.airtableResponse[i].fields.myD,
            date: self.airtableResponse[i].fields["Date Published"],
            body: self.airtableResponse[i].fields.Body,
            catdesign: self.airtableResponse[i].fields.CatDesign,
            catdev: self.airtableResponse[i].fields.CatDev,
            image: self.airtableResponse[i].fields.Image[0].url,
            slug: self.airtableResponse[i].fields.Slug
          };
          projectList.push(project);
        }
      }
      return projectList.filter(function(p) {
        return p.catdesign;
      });
    }
  },
  mixins: [Vue2Filters.mixin]
};
</script>
