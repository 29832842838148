<template>
  <div class="email-social">
    <h4>Contact me directly</h4>
    <h2>
      <a href="mailto:message@andries.ch" aria-label="Andries' e-mail address"
        >message@andries.ch</a
      >
    </h2>
    <!-- <div class="whatsapp">
      <h4>WhatsApp Contact</h4>
      <div id="whats-card">
        <h2>Scan this contact card with your mobile phone's camera.</h2>
        <a href="https://wa.me/qr/ONDBWRQDHMHUL1" target="_blank">
          <img src="../assets/qr-whatsapp-contact.svg" alt="WhatsApp QR Code" />
        </a>
      </div>
    </div> -->
    <div class="social-connection">
      <h4>Social Media</h4>
      <div id="social-icons">
        <a
          href="skype:andrieshannaart"
          aria-label="Andries' Skype address"
          target="_blank"
        >
          <img src="../assets/ic-skype.svg" alt="Skype icon" />
        </a>
        <a
          href="https://www.linkedin.com/in/andries-hannaart-8469536/"
          aria-label="Andries' LinkedIn"
          target="_blank"
          rel="noopener"
        >
          <img src="../assets/ic-linkedin.svg" alt="LinkedIn icon" />
        </a>
        <a
          href="https://www.codepen.io/ndrs/"
          aria-label="Andries' Codepen"
          target="_blank"
          rel="noopener"
        >
          <img src="../assets/ic-codepen.svg" alt="Codepen icon" />
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "email-social"
};
</script>
