<template>
  <div class="home">
    <Header />
    <ProjectsSummary />
    <Services />
    <AboutMe />
    <Reviews />
    <Contact />
  </div>
</template>

<script>
import Header from "@/components/Header";
import ProjectsSummary from "@/components/ProjectsSummary";
import Services from "@/components/Services";
import AboutMe from "@/components/AboutMe";
import Reviews from "@/components/Reviews";
import Contact from "@/components/Contact";

export default {
  name: "Home",
  components: {
    Header,
    ProjectsSummary,
    Services,
    AboutMe,
    Reviews,
    Contact
  },
  mounted() {
    //change nav link color when hover over dark bg section
    const servicesEl = document.getElementById("services");
    const myNav = document.getElementById("navList");
    function getPos() {
      let p = servicesEl.getBoundingClientRect().top;
      let pb = servicesEl.getBoundingClientRect().bottom;
      let nOffset = myNav.offsetHeight + myNav.offsetTop;

      if (p < nOffset) {
        myNav.classList.add("invertColor");
      } else if (p > nOffset) {
        myNav.classList.remove("invertColor");
      }
      if (pb < nOffset) {
        myNav.classList.remove("invertColor");
      }
    }
    window.addEventListener("scroll", getPos);
    getPos();
  },
  computed: {
    pageTitle: function() {
      return this.$route.meta.title;
    }
  }
};
</script>
