import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueSimpleMarkdown from "vue-simple-markdown";
import "vue-simple-markdown/dist/vue-simple-markdown.css";
import "vue-scrollto";
import Vuelidate from "vuelidate";
import VueGtag from "vue-gtag";
import VueMasonry from "vue-masonry-css";
import VueExpandableImage from "vue-expandable-image";

const VueScrollTo = require("vue-scrollto");
Vue.use(VueScrollTo, {
  duration: 0,
  y: true
});

Vue.use(VueSimpleMarkdown);

Vue.use(Vuelidate);

Vue.use(VueGtag, {
  config: { id: "UA-60794813-4" }
});

Vue.use(VueMasonry);

Vue.use(VueExpandableImage);

Vue.config.productionTip = false;

Vue.config.silent = true;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
