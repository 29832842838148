import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Projects from "../views/Projects.vue";
import Design from "../views/Design";
import Dev from "../views/Dev";
import Project from "../views/Project.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: "Graphic Design and Web Development by Andries Hannaart"
    }
  },
  {
    path: "/projects",
    name: "Projects",
    component: Projects
  },
  {
    path: "/design",
    name: "Design",
    component: Design
  },
  {
    path: "/dev",
    name: "Dev",
    component: Dev
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import("../views/blog/Blog.vue")
  },
  {
    path: "/reviews",
    name: "Reviews",
    component: () => import("../views/Reviews.vue")
  },
  {
    path: "/project/:slug",
    name: "project",
    component: Project
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

export default router;
