<template>
  <header>
    <div class="container">
      <section id="opening-text">
        <h1>
          <span>{{ headerTitle }}</span>
        </h1>
        <h3>
          {{ headingSubTxt }}
        </h3>
      </section>
    </div>
  </header>
</template>
<script>
export default {
  name: "Heading",
  props: {
    headerTitle: String,
    headingSubTxt: String,
    headerParagraph: String,
    headerSecondParagraph: String
  }
};
</script>
