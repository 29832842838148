<template>
  <section id="contact">
    <div class="container">
      <h1>Contact</h1>
      <div class="contact-cards">
        <contact-form></contact-form>
        <email-social></email-social>
        <blog-posts></blog-posts>
      </div>
    </div>
  </section>
</template>

<script>
import ContactForm from "@/components/ContactForm";
import BlogPosts from "@/components/BlogPosts";
import EmailSocial from "@/components/EmailSocial";

export default {
  name: "contact",
  components: {
    ContactForm,
    BlogPosts,
    EmailSocial
  }
};
</script>
