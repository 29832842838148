<template>
  <div class="project-card">
    <router-link :to="'/project/' + slug" :class="slug" class="project">
      <div class="project-container">
        <div class="project-header">
          <div class="project-title">
            <h4>{{ title }}</h4>
          </div>
          <div class="project-category">
            <span class="design" v-if="catdesign">{{ catdesign }}</span>
            <span class="dev" v-if="catdev">{{ catdev }}</span>
          </div>
        </div>
        <div class="project-content">
          <img :src="image" alt="Project example image" />
        </div>
        <div class="project-footer">
          <!-- v-if="slug" -->
          <img src="../assets/ic-arrow-light.svg" alt="View project arrow" />
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "ProjectCard",
  props: {
    title: String,
    body: String,
    catdesign: String,
    catdev: String,
    date: String,
    image: String,
    slug: String
  }
};
</script>
