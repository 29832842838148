<template>
  <div class="single-project">
    <header>
      <div class="container">
        <div id="page-heading">
          <h1>
            {{ project.title }}
          </h1>
        </div>
      </div>
    </header>

    <div class="container">
      <div class="row">
        <section id="project-body">
          <div class="header-project-image" v-if="project.images">
            <img
              v-for="image in project.images.slice(-1)"
              :key="'sliceOne' + image"
              :src="image.url"
            />
          </div>
          <vue-simple-markdown :source="project.body"></vue-simple-markdown>
          <section
            class="project-images"
            v-if="project.images && project.images.length > 3"
          >
            <span
              v-for="image in project.images.slice(1, 5)"
              :key="'sliceFour' + image"
            >
              <expandable-image
                :src="image.url"
                :alt="project.title"
                :close-on-background-click="true"
              />
            </span>
          </section>
          <section id="project-url" v-if="project.url">
            &#10140;
            <a :href="project.url" target="_blank">
              Visit Website
            </a>
          </section>
        </section>

        <section id="project-summary">
          <!-- 1/3rd -->
          <vue-simple-markdown :source="project.summary"></vue-simple-markdown>
          <a href="mailto:bonjour@andries.ch">Send me an e-mail</a>
          <router-link v-scroll-to="'#projects'" to="/projects"
            >More projects</router-link
          >
        </section>
      </div>
      <!-- row -->
    </div>
    <Contact />
  </div>
</template>

<script>
import Vue2Filters from "vue2-filters";
import ProjectsService from "@/services/ProjectsService";
import Contact from "@/components/Contact";
export default {
  name: "project",
  components: {
    Contact
  },
  data() {
    return {
      airtableResponse: []
    };
  },
  mounted: function() {
    let self = this;
    async function getProject() {
      try {
        const response = await ProjectsService.getProject(
          self.$route.params.slug
        );
        self.airtableResponse = response.data.records;
      } catch (err) {
        console.log(err);
      }
    }
    getProject();
  },
  computed: {
    project() {
      let self = this;
      if (self.airtableResponse[0]) {
        let thisProject = {
          title: self.airtableResponse[0].fields.Title,
          slug: self.airtableResponse[0].fields.Slug,
          snippet: self.airtableResponse[0].fields.Excerpt,
          images: self.airtableResponse[0].fields.Image,
          body: self.airtableResponse[0].fields.Body,
          summary: self.airtableResponse[0].fields.ProjectSummary,
          url: self.airtableResponse[0].fields.url
        };
        return thisProject;
      }
      return this;
    }
  },
  mixins: [Vue2Filters.mixin]
};
</script>
